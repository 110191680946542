<template>
    <div>
      <PageLoader v-bind:storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-layout wrap justify-center style="background-color: #eefff2">
        <v-flex xs11 sm10 text-left pt-10 pb-4>
          <span
            style="font-family: poppinsmedium; font-size: 25px; color: #000000"
            >My Courses</span
          >
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12>
          <MyCourseItem :storage="course" />
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12>
          <v-card color="#F9F9F9">
            <v-layout wrap justify-center>
              <v-flex xs11 sm10>
                <v-layout wrap pb-10 pt-2>
                  <v-flex xs12 text-left py-5>
                    <span
                      style="
                        font-family: poppinssemibold;
                        color: #000000;
                        letter-spacing: 1px;
                        font-size: 20px;
                      "
                      >Related Courses</span
                    >
                  </v-flex>
                  <v-flex xs12 sm6 md4 pa-6 v-for="(item, i) in popular" :key="i">
                    <v-card rounded="md">
                      <v-layout wrap>
                        <v-flex xs12>
                          <v-img
                            :src="mediaURL + item.frontImage"
                            :lazy-src="mediaURL + item.frontImage"
                            :alt="item.frontImage"
                            eager
                            ><template v-slot:placeholder>
                              <ImageLoader /> </template></v-img
                        ></v-flex>
                        <v-flex xs12 px-5 py-5 text-left>
                          <span
                            style="
                              color: #3cb043;
                              font-family: poppinsmedium;
                              font-size: 20px;
                            "
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '24px'
                                  : '20px',
                            }"
                            >US ${{ item.offerPrice }} &nbsp;</span
                          >
                          <span
                            style="
                              color: #7f7f7f;
                              font-family: poppinslight;
                              font-size: 12px;
                            "
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '14px'
                                  : '12px',
                            }"
                            >(Inclusive of all Taxes)</span
                          >
                        </v-flex>
                        <v-flex xs12 px-5 text-left>
                          <span
                            style="
                              font-family: poppinsbold;
                              color: black;
                              font-size: 18px;
                            "
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '22px'
                                  : '18px',
                            }"
                            >{{ item.courseName }}</span
                          >
                        </v-flex>
                        <v-flex xs12 text-left px-5 pt-2 pb-4>
                          <span
                            style="
                              font-family: poppinslight;
                              color: black;
                              font-size: 14px;
                            "
                            :style="{
                              'font-size':
                                $vuetify.breakpoint.name == 'xl'
                                  ? '18px'
                                  : '14px',
                            }"
                            v-html="item.description"
                          ></span>
                        </v-flex>
                        <v-flex xs12 text-right px-5 pt-2 pb-10>
                          <v-btn
                            dark
                            small
                            color="#3CB043"
                            :ripple="false"
                            :to="'/courseDetails?id=' + item._id"
                            ><span
                              style="
                                font-family: opensanssemibold;
                                text-transform: none;
                              "
                              >Details</span
                            ></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  import MyCourseItem from "./myCourseItem";
  export default {
    components: {
        MyCourseItem,
    },
    data() {
      return {
        appLoading: false,
        ServerError: false,
        user: {},
        home: [],
        fab: true,
        slideNo: 0,
        slideInterval: 4000,
        course: [],
        popular: [],
      };
    },
    beforeMount() {
      this.getData();
      this.getPopular();
    },
  
    methods: {
      winStepper(item) {
        if (item.getData) {
          this.getData();
        }
        if (item.response) {
          this.msg = item.response.msg;
          this.showSnackBar = true;
        }
      },
      getData() {
        this.appLoading = true;
        axios({
          url: "/user/course/purchase/list",
          method: "POST",
          headers: {
          token: localStorage.getItem("token"),
        },
        })
          .then((response) => {
            this.appLoading = false;
            this.course = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getPopular() {
        this.appLoading = true;
        axios({
          url: "/course/popular/course/list/cart",
          method: "GET",
        })
          .then((response) => {
            this.appLoading = false;
            this.popular = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>