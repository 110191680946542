var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageLoader',{attrs:{"storage":_vm.appLoading}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-layout',{staticStyle:{"background-color":"#eefff2"},attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs11":"","sm10":"","text-left":"","pt-10":"","pb-4":""}},[_c('span',{staticStyle:{"font-family":"poppinsmedium","font-size":"25px","color":"#000000"}},[_vm._v("My Courses")])])],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('MyCourseItem',{attrs:{"storage":_vm.course}})],1)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"color":"#F9F9F9"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs11":"","sm10":""}},[_c('v-layout',{attrs:{"wrap":"","pb-10":"","pt-2":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":"","py-5":""}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","color":"#000000","letter-spacing":"1px","font-size":"20px"}},[_vm._v("Related Courses")])]),_vm._l((_vm.popular),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","sm6":"","md4":"","pa-6":""}},[_c('v-card',{attrs:{"rounded":"md"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + item.frontImage,"lazy-src":_vm.mediaURL + item.frontImage,"alt":item.frontImage,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)})],1),_c('v-flex',{attrs:{"xs12":"","px-5":"","py-5":"","text-left":""}},[_c('span',{staticStyle:{"color":"#3cb043","font-family":"poppinsmedium","font-size":"20px"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '24px'
                              : '20px',
                        })},[_vm._v("US $"+_vm._s(item.offerPrice)+"  ")]),_c('span',{staticStyle:{"color":"#7f7f7f","font-family":"poppinslight","font-size":"12px"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '14px'
                              : '12px',
                        })},[_vm._v("(Inclusive of all Taxes)")])]),_c('v-flex',{attrs:{"xs12":"","px-5":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"black","font-size":"18px"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '22px'
                              : '18px',
                        })},[_vm._v(_vm._s(item.courseName))])]),_c('v-flex',{attrs:{"xs12":"","text-left":"","px-5":"","pt-2":"","pb-4":""}},[_c('span',{staticStyle:{"font-family":"poppinslight","color":"black","font-size":"14px"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '18px'
                              : '14px',
                        }),domProps:{"innerHTML":_vm._s(item.description)}})]),_c('v-flex',{attrs:{"xs12":"","text-right":"","px-5":"","pt-2":"","pb-10":""}},[_c('v-btn',{attrs:{"dark":"","small":"","color":"#3CB043","ripple":false,"to":'/courseDetails?id=' + item._id}},[_c('span',{staticStyle:{"font-family":"opensanssemibold","text-transform":"none"}},[_vm._v("Details")])])],1)],1)],1)],1)})],2)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }