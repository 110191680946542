<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap justify-center py-5 v-if="storage.length > 0">
          <v-flex xs11 sm10>
            <v-layout wrap justify-center>
              <v-flex xs12 py-4 v-for="(item, i) in storage" :key="i">
                <v-card outlined>
                  <v-layout wrap justify-center pa-4>
                    <v-flex xs12 sm4 md1 pt-4>
                      <v-img
                        height="100px"
                        width="110px"
                        :src="mediaURL + item.courseId.frontImage"
                      ></v-img>
                    </v-flex>
                    <v-flex xs12 sm7 md7 pa-4 text-left>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="
                              color: #7f7f7f;
                              font-family: poppinsregular;
                              font-size: 13px;
                            "
                            >Courses | {{ item.courseId.category.name }}</span
                          >
                        </v-flex>
                        <v-flex xs12 pt-1>
                          <span
                            style="
                              font-family: poppinssemibold;
                              font-size: 14px;
                              color: #000000;
                            "
                            >{{ item.courseId.courseName }}</span
                          >
                        </v-flex>
                        <v-flex xs12 pt-2>
                          <span
                            style="
                              font-family: poppinsmedium;
                              font-size: 12px;
                              color: #000000;
                            "
                            v-html="item.courseId.description"
                          ></span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs4 sm2 md1>
                      <v-layout wrap fill-height py-5>
                        <v-flex xs12>
                          <v-divider vertical></v-divider>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs4 sm3 md3 pt-3 tex-center>
                        <v-layout wrap pt-2>
                            <v-flex xs12>
                      <v-btn color="#3CB043" :to="'/myCourseDetails?id=' + item.courseId._id">
                        <span
                          style="
                            font-family: opensanssemibold;
                            font-size: 14px;
                            color: #fff;
                            text-transform: none;
                          "
                          >View course</span
                        ></v-btn
                      >
                      </v-flex>
                      <v-flex xs12 pt-2>
                      <span
                        style="
                          font-family: opensanssemibold;
                          font-size: 14px;
                          color: #3cb043;
                          text-align: center;
                        "
                        >In Progress</span
                      >
                    </v-flex>
                        </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-else>
          <v-flex xs12 py-16 text-center>
            <span style="font-family: poppinsbold; font-size: 25px"
              >You currently do not possess any courses</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      subId: "",
      courses: [],
      snackbar: false,
      msg: null,
    };
  },
};
</script>
